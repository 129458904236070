export const artCons: any = {
    hcpMainMenu: "Main Navigational Menu HCP Retina Brazil (PT)",
    patMainMenu: "Main Navigational Menu Consumer Retina Brazil (PT)",
    hcpFooterMenu: "Footer Navigation Menu HCP Retina Brazil (PT)",
    patFooterMenu: "Footer Navigation Menu Consumer Retina Brazil (PT)",
    carousel: "",
    banner: "",
    hcpFooter: "Footer - HCP - Retina Brazil (PT)",
    patFooter: "Footer - Consumer - Retina Brazil (PT)",
    dtcExitPopup: "Exit Popup Brazil (PT)",
    hcpExitPopup: "Exit Popup HCP Brazil (PT)",
    hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
    hcpTopNav: "Top Navigation Menu HCP Retina Brazil (PT)",
    patTopNav: "Top Navigation Menu Retina Brazil (PT)",
    hcpFooterCallout: "HCP Homepage Footer Navigation Callout HTML - Canada",
    siteLogo: "Site logos - Retina Brazil (PT)",
    socialBlock: "Footer Social Icons",
    hcpSitemapLabel: "HCP Sitemap - Retina Brazil (PT)",
    patSitemapLabel: "DTC Sitemap - Retina Brazil (PT)",
    privacyPara: "Retina Canada Privacy Policy - DTC - Retina Brazil (PT)",
    bannerSitemapHCP: "Banner - HTML - Sitemap - HCP - Brazil (PT)",
    bannerSitemapDTC: "Banner - HTML - Sitemap - DTC - Brazil (PT)",
    bannerPrivacyHCP: "Banner - HTML - Privacy - HCP - Brazil (PT)",
    bannerPrivacyDTC: "Banner - HTML - Privacy - DTC - Brazil (PT)",
    backToTopGTMPP: "back to top - privacy policy",
    backToTopGTMSM: "back to top - site map",
    backToTopGTMSMHCP: "back to top - site map - hcp",
    backToTopGTMPPHCP: "back to top - privacy policy - hcp",
    footerClassName: "common-footer"
  }
